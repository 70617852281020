import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };
  return (
    <>
      <section className="side_bar_section">
        <Link
          to="/dashboard"
          className={`dashboard_title ${isActive("dashboard")}`}
        >
          <div className="dash_title">
            <img
              src={require("../images/landing_new/dashboard_icon.png")}
              className="dashboard_icon"
              alt="dashboard"
            />
            <h3 className="dash_title_text">Dashboard</h3>
          </div>
        </Link>
        <Link
          to="/security"
          className={`dashboard_title ${isActive("security")}`}
        >
          <div className="dash_title ">
            <img
              src={require("../images/landing_new/security_icon.png")}
              className="dashboard_icon"
              alt="security"
            />
            <h3 className="dash_title_text">Security</h3>
          </div>
        </Link>
        <Link to="/kyc" className={`dashboard_title ${isActive("kyc")}`}>
          <div className="dash_title ">
            <img
              src={require("../images/landing_new/verify_icon.png")}
              className="dashboard_icon"
              alt="kyc"
            />
            <h3 className="dash_title_text">Identity Verification</h3>
          </div>
        </Link>

        <Link
          to="/Withdraw"
          className={`dashboard_title ${isActive("Withdraw")}`}
        >
          <div className="dash_title ">
            <img
              src={require("../images/landing_new/Withdraw.png")}
              className="dashboard_icon"
              alt="withdraw"
            />
            <h3 className="dash_title_text">Withdraw</h3>
          </div>
        </Link>
        <Link
          to="/staking"
          className={`dashboard_title ${isActive("staking")}`}
        >
          <div className="dash_title ">
            <img
              src={require("../images/landing_new/stake.png")}
              className="dashboard_icon stake_icon"
              alt="stake"
            />
            <h3 className="dash_title_text">Staking</h3>
          </div>
        </Link>

        <Link
          to="/history"
          className={`dashboard_title ${isActive("history")}`}
        >
          <div className="dash_title">
            <img
              src={require("../images/landing_new/history_icon.png")}
              className="dashboard_icon"
              alt="history"
            />
            <h3 className="dash_title_text">History</h3>
          </div>
        </Link>
        <Link
          to="/support"
          className={`dashboard_title ${isActive("support")}`}
        >
          {" "}
          <div className="dash_title  ">
            <img
              src={require("../images/landing_new/support_icon.png")}
              className="dashboard_icon support_icon"
              alt="support"
            />
            <h3 className="dash_title_text">Support</h3>
          </div>
        </Link>

        <Link className={`dashboard_title ${isActive("logout")}`}>
          <div
            className="dash_title logout_dash_title"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModallogut"
          >
            <img
              src={require("../images/landing_new/logout.png")}
              className="dashboard_icon support_icon"
              alt="logout"
            />
            <h3 className="dash_title_text">Logout</h3>
          </div>
        </Link>
      </section>
    </>
  );
};

export default Sidebar;
